import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3de0f4ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  slot: "start",
  class: "btn cta-button ion-activatable ripple-parent"
}
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
    ]),
    _createVNode(_component_ion_ripple_effect)
  ]))
}