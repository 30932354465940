const state = {
    isUpdating: false,
    user      : {
        isPlanner: true,
    }
};

const mutations = {
    setIsUpdating(state: any, { isUpdating }: any) {
        state.isUpdating = isUpdating;
    },

    setUser(state: any, { user }: any) {
        state.user = user;
    }
};

const getters = {
    /**
     * Get the is updating state.
     * @param state
     */
    getIsUpdating(state: any) {
        return state.isUpdating;
    },

    /**
     * Get the user state.
     * @param state
     */
    getUser(state: any) {
        return state.user;
    }
};

const actions = {
    async setIsUpdating(context: any, { isUpdating }: any) {
        await context.commit('setIsUpdating', { isUpdating });
    }
};

export const general = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
