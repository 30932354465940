
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'BasePanel',

        computed: {
            contentSlot() {
                return this.$slots.content;
            },
            ctaSlot() {
                return this.$slots.cta;
            },
        },
    });
