import { toastController } from "@ionic/vue";

export default {
    /**
     * Presents a single toast
     * @param message
     * @param duration
     * @param color
     * @param position
     */
    presentToast: async function (message: string, duration: number, color: string, position: any = 'bottom') {
        const toast = await toastController.create({
            cssClass: 'waterluxe-message',
            message,
            duration,
            position,
            color,
        });

        await toast.present();
    },

    /**
     * Presents an array of toasts
     * @param array
     * @param duration
     * @param color
     */
    async presentArrayOfToasts(array: Array<string>, duration: number, color: string) {
        let finalMessage = "";

        array.forEach((message, index) => {
            if (index === 0) {
                finalMessage += '<b>' + message + "</b> </br>";
            } else {
                finalMessage += '<li>' + message + "</li>";
            }
        })

        const toast = await toastController.create({
            cssClass: 'array-toast',
            message : finalMessage,
            duration,
            position: 'bottom',
            color,
        });

        await toast.present();
    }
}