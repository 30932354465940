
    import { defineComponent } from 'vue';
    import { store } from '@/store';
    import BasePanel from '@/components/BasePanel.vue';
    import OrderService from '@/services/order.service';
    import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonLabel, IonPage, IonRippleEffect, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/vue';
    import VueSignaturePad from 'vue3-signature-pad';

    export default defineComponent({
        name      : 'OrderAgreement',
        components: {
            VueSignaturePad,
            BasePanel,
            IonContent,
            IonButton,
            IonPage,
            IonTitle,
            IonButtons,
            IonInput,
            IonBackButton,
            IonHeader,
            IonToolbar,
            IonRippleEffect,
            IonLabel,
            IonSelect,
            IonSelectOption
        },
        data() {
            return {
                orderHasChanged       : null as any,
                BackIcon              : require('@/assets/images/icons/back.svg'),
                SearchIcon            : require('@/assets/images/icons/search.svg'),
                signatureOptions      : {
                    velocityFilterWeight: 0,
                    throttle            : 8,
                    minDistance         : 0,
                    onBegin             : () => {
                        this.signatureEmpty = (this.$refs.signaturePad as any).isEmpty()
                    },
                    onEnd               : () => {
                        this.signatureEmpty = (this.$refs.signaturePad as any).isEmpty()
                    },
                },
                selectInterfaceOptions: {
                    reference  : 'event',
                    translucent: true
                },
                signatureEmpty        : true,
            };
        },

        mounted() {
            this.whenOrderIsFinished()
        },

        methods: {
            async orderInformationChanged(hasChanged: boolean) {
                this.orderHasChanged = hasChanged;
                switch (hasChanged) {
                    case true:
                        // TODO: Sent the mechanic to the "edit order information view"
                        break;

                    case false:
                        // TODO: Sent the mechanic to the "agree order view"
                        break;

                    default:
                        // TODO: Sent the mechanic to the "agree order view"
                        break;
                }
            },

            whenOrderIsFinished() {
                if (this.order.is_finished) {
                    this.orderHasChanged = false
                }
            },

            onBegin() {
                this.signatureEmpty = (this.$refs.signaturePad as any).isEmpty();
            },

            /**
             * Clears the current drawings in the canvas.
             **/
            clearCanvas() {
                (this.$refs.signaturePad as any).clearSignature();
                this.signatureEmpty = true;
            },

            /**
             * Resize window to make sure the canvas works.
             **/
            resizeWindow() {
                if (document.querySelector('canvas') as any) {
                    (window as any).w = (document.querySelector('canvas') as any).width = '600';
                    return (window as any).h = (document.querySelector('canvas') as any).height = '160';
                }
            },

            /**
             * Move on to the payment.
             */
            toPayment() {
                const { isEmpty, data } = (this.$refs.signaturePad as any).saveSignature();

                OrderService.sendCustomerSignature(data).finally(() => {
                    if (!isEmpty && this.totalPrice > 0) {
                        // If the signature is filled and the totalPrice is greater than 0.
                        this.$router.push({ name: 'Checkout' });
                    } else if (this.totalPrice === 0 && !isEmpty) {
                        // If the signature is filled and the totalPrice is 0.
                        this.$router.push({ name: 'PaymentSuccess' });
                    }
                });
            }
        },

        computed: {
            order() {
                // TODO: Fetch the item from the API or VueX store once the page refreshes.
                return store.getters['order/getSelectedOrder'];
            },

            totalPrice() {
                let totalPrice = 0;

                const orderProducts = store.getters['order/getSelectedOrder'].order_lines;

                if (orderProducts && orderProducts.length > 0) {
                    orderProducts.forEach((product: any) => {
                        totalPrice += (product.price * product.quantity);
                    });
                }

                return totalPrice;
            },
        },

        watch: {
            orderHasChanged() {
                this.resizeWindow();
            }
        }
    });
