
import {defineComponent} from 'vue';
import {IonContent, IonHeader, IonModal, IonTitle, IonToolbar} from '@ionic/vue';

export default defineComponent({
    name: 'SheetModal',
    components: {IonModal, IonContent, IonHeader, IonToolbar, IonTitle},
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
});
