
    import { defineComponent } from 'vue';
    import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
    import BasePanel from '@/components/BasePanel.vue';
    import OrderService from '@/services/order.service';

    export default defineComponent({
        name: 'PayLaterSuccess',
        components: {
            IonContent,
            BasePanel,
            IonPage,
            IonTitle,
            IonButtons,
            IonBackButton,
            IonHeader,
            IonToolbar,
        },
        data() {
            return {
                BackIcon: require('@/assets/images/icons/back.svg'),
            };
        },

        methods: {
            backToPlanning() {
                OrderService.getAllOrders().then(() => {
                    this.$router.push({
                        name: 'Planning',
                    });
                });
            },
        },
    });
