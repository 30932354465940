
    import { defineComponent } from 'vue';
    import { store } from '@/store';
    import BasePanel from '@/components/BasePanel.vue';
    import {
        IonBackButton,
        IonButton,
        IonButtons,
        IonContent,
        IonHeader,
        IonImg,
        IonInput,
        IonList,
        IonPage,
        IonRippleEffect,
        IonSearchbar,
        IonSlide,
        IonSlides,
        IonThumbnail,
        IonTitle,
        IonToolbar,
        IonTextarea
    } from '@ionic/vue';
    import ProductListItem from '@/components/ProductListItem.vue';
    import { Camera, CameraResultType } from '@capacitor/camera';
    import CardModal from "@/components/CardModal.vue";
    import OrderService from "@/services/order.service";
    import BaseLoader from "@/components/BaseLoader.vue";
    import debounce from "lodash/debounce";
    import ToastService from "@/services/toast.service";
    import dayjs from "dayjs";
    import ImageLoaderOverlay from "@/components/ImageLoaderOverlay.vue";
    import SheetModal from "@/components/SheetModal.vue";

    export default defineComponent({
        name      : 'OrderDetail',
        components: {
            SheetModal,
            ImageLoaderOverlay,
            BaseLoader,
            ProductListItem,
            CardModal,
            BasePanel,
            IonContent,
            IonPage,
            IonInput,
            IonSearchbar,
            IonTitle,
            IonButtons,
            IonBackButton,
            IonHeader,
            IonToolbar,
            IonSlides,
            IonSlide,
            IonImg,
            IonThumbnail,
            IonButton,
            IonList,
            IonRippleEffect,
            IonTextarea
        },

        data() {
            return {
                dateOptions                : { year: 'numeric', month: 'long', day: 'numeric' },
                BackIcon                   : require('@/assets/images/icons/back.svg'),
                SearchIcon                 : require('@/assets/images/icons/search.svg'),
                updatedEmail               : '',
                imageSlideOptions          : {
                    initialSlide: 1,
                },
                destinationCoordinates     : {
                    lat: 0.000000,
                    lng: 0.000000,
                },
                amountOfLoadedImages       : 0,
                showInstallationInformation: false,
                showServiceInformation     : false,
                searchbarInput             : '',
                copyOfImages               : [] as any[],
                isDisabled                 : false,
                isFiltering                : false,
                imagesHaveChanged          : false,
                selectedImage              : { uploadedAt: '1 jan. 2999', url: null },
                isOrderLineWatcherDisabled : false,
            };
        },

        methods: {
            /**
             * Sorts the order lines by price asc.
             */
            sortedOrderLines(orderLines: any[], addedByMechanic: number|null) {
                // sort the orderLines by price descending and check if the is_updated value equals updatedByMechanic.
                return [...orderLines].filter((orderLine: any) => {
                    if (addedByMechanic) {
                        return orderLine.is_added_by_mechanic === addedByMechanic;
                    }

                    return !orderLine.is_added_by_mechanic;
                });
            },

            /**
             * Update the mechanic comment of the order with the receiving value from the ion-textarea for the comment
             */
            updateMechanicComment(value: string) {
                store.commit('order/updateOrderMechanicComment', value);
            },
            /**
             * Update the customers e-mail address.
             */
            updateEmailAddress() {
                if (this.validEmail(this.updatedEmail)) {
                    OrderService.updateCustomerEmailAddress(this.order.id, this.updatedEmail).finally(() => {
                        (this.$refs['edit-email-modal'] as any).isOpen = false;
                    });
                } else {
                    ToastService.presentToast('Voer een valide e-mailadres in', 3500, 'danger');
                }
            },

            /**
             * is this group in the selected addable product group.
             * @param groupId
             */
            isGroupInSelectedAddableProductGroup(groupId: number) {
                return this.selectedAddableProductGroup.find((group: number) => group === groupId);
            },

            /**
             * Set image loaded.
             * @param image
             */
            setImageLoaded(image: any) {
                image.loaded = true;
            },

            /**
             * Checks if an emailaddress is valid.
             * @param email
             */
            validEmail: function (email: string) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            /**
             * Opens the edit email modal.
             */
            openEditEmailModal() {
                (this.$refs['edit-email-modal'] as any).isOpen = true;
            },

            /**
             * Creates a comparable boolean from a value that is supposed to be a boolean.
             * @param boolean
             */
            booleanFormatter(boolean: boolean|number|string) {
                switch (boolean) {
                    case 0:
                        return false;
                    case 1:
                        return true;
                    case 'true':
                        return true;
                    case 'false':
                        return false;
                    case '0':
                        return false;
                    case '1':
                        return true;
                    default:
                        return boolean;
                }
            },

            /**
             * Formats a given value to a more readable value, to show to the mechanic.
             * @param value
             */
            formatInformationValue(value: string|boolean) {
                switch (value) {
                    case 'true':
                        return 'Ja';
                    case 'false':
                        return 'Nee';
                    case false:
                        return 'Nee';
                    case true:
                        return 'Ja';
                    case null:
                        return '-';
                    case undefined:
                        return '-';
                    default:
                        return value;
                }
            },

            /**
             * Opens the image modal.
             * @param image
             * @param imageIndex
             */
            openImageModal(image: any, imageIndex: number) {
                // Sets the select image to be the one that's clicked upon.
                this.selectedImage = image;

                // Set the initial slide to the given index.
                this.imageSlideOptions.initialSlide = imageIndex;

                // The images in the slider are not loaded yet, we want the value to be false before we load them.
                // image.loaded = false;

                // Open modal.
                (this.$refs['show-image-modal'] as any).isOpen = true;
            },

            /**
             * Sets a new selected image
             * @param image
             */
            setSelectedImage(image: any) {
                this.selectedImage = image;
            },

            /**
             * Opens the product modal.
             */
            async openProductModal() {
                (this.$refs['add-product-modal'] as any).isOpen = true;
            },

            /**
             * Adds selected products to the appointment/order and closes modal.
             */
            async addSelectedProductsToOrder() {
                await store.dispatch('order/addSelectedProductsToOrder');
                (this.$refs['add-product-modal'] as any).isOpen = false;
            },

            /**
             * Toggle the product selection.
             * @param sku
             */
            toggleProductSelection(sku: number) {
                store.commit('order/toggleSelectionOfCertainProduct', { productSku: sku });
            },

            /**
             * Toggle the accordion for installation information.
             */
            toggleInstallationInformation() {
                this.showInstallationInformation = !this.showInstallationInformation;
            },

            /**
             * Toggle the accordion for service information.
             */
            toggleServiceInformation() {
                this.showServiceInformation = !this.showServiceInformation;
            },

            beforeEnter(el: any) {
                el.style.height = '0';
            },

            enter(el: any) {
                el.style.height = `${el.scrollHeight}px`;
            },

            beforeLeave(el: any) {
                el.style.height = `${el.scrollHeight}px`;
            },

            leave(el: any) {
                el.style.height = '0';
            },

            /**
             * Update the images.
             */
            updateImages: async function () {
                await store.dispatch('general/setIsUpdating', { isUpdating: true });

                // First we update the order, this is because the mechanic might have added products or changed quantities/prices.
                await OrderService.updateOrder();

                // Check if one of the images is a newly added image.
                const hasNewUploadedImages = this.copyOfImages.some((image: any) => image.isNewImage);

                // If we have new images, we need to upload them.
                if (hasNewUploadedImages) {
                    try {
                        const newImages = this.copyOfImages.filter((image: any) => image.isNewImage);
                        await OrderService.updateAllImagesAndFetchOrder(newImages);

                        // Set them all to not be new images.
                        this.copyOfImages.forEach((image: any) => {
                            image.isNewImage = false;
                        });
                    } catch {
                        await ToastService.presentToast("Er is een fout opgetreden bij het opslaan van de foto's", 2500, 'danger');
                    }
                }

                this.imagesHaveChanged = false;
                await store.dispatch('general/setIsUpdating', { isUpdating: false });
            },

            /**
             * Calls the update order event with a debounce.
             */
            updateOrder: debounce(async () => {
                await store.dispatch('general/setIsUpdating', { isUpdating: true });
                await OrderService.updateOrder().then(async () => {
                    await ToastService.presentToast('Wijzigingen opgeslagen', 2500, 'success', 'top');
                }).finally(async () => {
                    await store.dispatch('general/setIsUpdating', { isUpdating: false });
                    store.commit('order/setOrderLinesHaveChanged', { orderLinesHaveChanged: false });
                });
            }, 500),

            toNextStepFinished() {
                // Checks if we have a product in our order line that requires a serial number.
                const serialNumberProduct = this.order.order_lines.find((orderLine: any) => {
                    return orderLine.product.has_serial_numbers === true
                });

                if (serialNumberProduct) {
                    this.$router.push({ name: 'ProductSerialNumber', params: { id: this.order.id } });
                } else {
                    this.$router.push({ name: 'OrderAgreement', params: { id: this.order.id } });
                }
            },

            uppercaseFirstLetter(name: string) {
                return name.charAt(0).toUpperCase() + name.slice(1);
            },

            /**
             * Triggered when user clicks on the 'To next step' button
             */
            async toNextStep() {
                if (!this.isUpdating) {
                    this.isOrderLineWatcherDisabled = true;
                    // Notifies the user we are currently doing things, through loading indicator.
                    await store.dispatch('general/setIsUpdating', { isUpdating: true });

                    // Checks if we have a product in our order line that requires a serial number.
                    const serialNumberProduct = this.order.order_lines.find((orderLine: any) => {
                        return orderLine.product.has_serial_numbers === true
                    });

                    // If we have at least one product that needs a serial number;
                    // Updates the order and pushes to the serial number step.
                    OrderService.updateOrder().then((res) => {
                        if (res) {
                            if (serialNumberProduct) {
                                this.$router.push({ name: 'ProductSerialNumber', params: { id: this.order.id } });
                            } else {
                                this.$router.push({ name: 'OrderAgreement', params: { id: this.order.id } });
                            }
                        }
                    }).finally(() => {
                        store.dispatch('general/setIsUpdating', { isUpdating: false });
                    });
                }
            },

            /**
             * Formats a price value.
             * @param price
             */
            priceFormatting(price: any) {
                return new Intl.NumberFormat(
                    'nl-NL',
                    {
                        style       : 'currency',
                        currency    : 'EUR',
                        currencySign: 'accounting'
                    }).format(price);
            },

            /**
             * Remove an images from the images
             * @param imageIndex
             */
            removeImage(imageIndex: number) {
                if (this.copyOfImages[imageIndex].isNewImage) {
                    this.copyOfImages.splice(imageIndex, 1);
                } else {
                    ToastService.presentToast('Deze afbeelding kan niet worden verwijderd', 3500, 'danger');
                }

                // Check if there is any image that isNewImage
                this.imagesHaveChanged = this.copyOfImages.some((image: any) => image.isNewImage);
            },

            /**
             * Takes a picture.
             */
            async takePicture() {
                const imageElement = {} as any;

                const image = await Camera.getPhoto({
                    quality     : 70,
                    allowEditing: true,
                    resultType  : CameraResultType.Base64,
                    width       : 1000,
                    height      : 1000,
                });

                const dateOfToday = new Date();

                // Can be set to the src of an image now
                imageElement.src = 'data:image/png;base64,' + image.base64String;
                imageElement.isNewImage = true;
                imageElement.loaded = false;
                imageElement.uploadedAt = dayjs(dateOfToday).format('DD MMM. YYYY');

                // Set it to the copy of images, we don't want a BASE64 string in our localstorage.
                this.copyOfImages.unshift(imageElement);

                this.imagesHaveChanged = true;
            },

            /**
             * Format a price value.
             * @param price
             */
            formatPrice(price: number) {
                return new Intl.NumberFormat('nl-NL', {
                    style       : 'currency',
                    currency    : 'EUR',
                    currencySign: 'accounting'
                }).format(price);
            },

            /**
             * Navigate to location.
             */
            async navigateToLocation() {
                const destination = [
                    this.order.customer.country_code,
                    this.order.customer.city,
                    this.order.customer.streetname,
                    this.order.customer.house_number,
                    this.order.customer.house_number_addition,
                ].join(' ');

                await window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${destination}`);
            },

            /**
             * Add the value to the selectedProductGroup to switch categories
             * @param value
             */
            setSelectedProductGroup(value: any) {
                store.commit('order/setSelectedAddableProductGroup', { addableProductGroupId: value.id });
            },

            /**
             * Toggle the history modal.
             */
            toggleHistoryModal() {
                if (this.order.appointment_history.length > 1) {
                    (this.$refs['open-history-modal'] as any).isOpen = !(this.$refs['open-history-modal'] as any).isOpen
                }
            }
        },

        computed: {
            installationTitle() {
                if (this.order.installation_information && this.order.service_information) {
                    return 'Oorspronkelijke installatie situatie';
                }
                return 'Installatie informatie';
            },

            authenticatedUserIsPlanner() {
                return store.getters['auth/getUserIsPlanner'];
            },

            userId() {
                return store.getters['auth/getUserId'];
            },

            user() {
                return store.getters['general/getUser'];
            },

            order() {
                return store.getters['order/getSelectedOrder'];
            },

            orderLineHasChanged() {
                return store.getters['order/getOrderLinesHaveChanged'];
            },

            orderLines() {
                return JSON.parse(JSON.stringify(store.getters['order/getSelectedOrder'].order_lines));
            },

            isUpdating() {
                return store.getters['general/getIsUpdating'];
            },

            sum() {
                return store.getters['order/getSelectedOrder'].installation_information.length - 1;
            },

            serviceInformationSum() {
                return store.getters['order/getSelectedOrder'].service_information.length - 1;
            },

            selectedAddableProducts() {
                return store.getters['order/getSelectedAddableProducts'];
            },

            totalPrice() {
                const totalPrice = store.getters['order/getTotalPrice'];

                return new Intl.NumberFormat('nl-NL', {
                    style       : 'currency',
                    currency    : 'EUR',
                    currencySign: 'accounting'
                }).format(totalPrice);
            },

            addableProducts: {
                get() {
                    return store.getters['order/getAddableProducts'];
                },

                set() {
                    return store.getters['order/getAddableProducts'];
                }
            },

            addableProductGroups() {
                return store.getters['order/getAddableProductGroups'];
            },

            selectedAddableProductGroup() {
                return store.getters['order/getSelectedAddableProductGroups'];
            },
        },
        mounted() {
            this.searchbarInput = store.getters['getProductSearchbarInput'];
            this.updatedEmail = this.order.customer.email;
            this.copyOfImages = [...this.order.images].map((image: any) => {
                return {
                    ...image,
                    isNewImage: false,
                    loaded    : false,
                };
            });

            // We don't want to be updating when we enter the page.
            store.commit('general/setIsUpdating', { isUpdating: false });
        },
        beforeRouteLeave() {
            this.isOrderLineWatcherDisabled = false;

            if (this.imagesHaveChanged) {
                this.updateImages();
            }
        },

        watch: {
            searchbarInput() {
                store.commit('order/setProductSearchBarInput', { searchbarInput: this.searchbarInput });

                this.addableProducts = store.getters['order/getAddableProducts'];
            },
            orderLines(current, old) {
                if (JSON.stringify(current) !== JSON.stringify(old)) {
                    store.commit('order/setOrderLinesHaveChanged', { orderLinesHaveChanged: true });
                }
            }
        }
    });
