
    import { defineComponent } from 'vue';
    import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewDidEnter, onIonViewDidLeave, onIonViewWillLeave } from '@ionic/vue';
    import { store } from '@/store';
    import BasePanel from '@/components/BasePanel.vue';
    import BaseLoader from '@/components/BaseLoader.vue';
    import useMolliePayment from '@/composables/useMolliePayment';

    export default defineComponent({
        name      : 'PinDevice',
        components: {
            BaseLoader,
            IonContent,
            BasePanel,
            IonPage,
            IonTitle,
            IonButtons,
            IonBackButton,
            IonHeader,
            IonToolbar,
        },

        data() {
            return {
                BackIcon: require('@/assets/images/icons/back.svg'),
            };
        },

        setup() {
            const { startInterval, stopInterval, loadingQRCode } = useMolliePayment();

            onIonViewDidEnter(() => {
                startInterval();
            });

            onIonViewDidLeave(() => {
                stopInterval();
            });

            onIonViewWillLeave(() => {
                stopInterval();
            });

            return {
                loadingQRCode,
                stopInterval,
            };
        },

        computed: {
            order() {
                return store.getters['order/getSelectedOrder'];
            },
        },

        ionViewWillLeave() {
            this.stopInterval();
        },
    });
