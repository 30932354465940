import { v4 as uuid } from "uuid";
import OrderService from "@/services/order.service";

const state = {
    selectedOrder               : null,
    addableProducts             : [],
    addableProductGroups        : [],
    productSearchbarInput       : '',
    orderLinesHaveChanged       : false,
    selectedAddableProductGroups: [],
};

const mutations = {
    /**
     * Set a selected order.
     * @param state
     * @param order
     */
    setSelectedOrder(state: { selectedOrder: any }, order: any) {
        const orderLines = order.order_lines.map((orderLine: any) => {
            const { serial_numbers = [], validated_serial_numbers = [] } = orderLine.product;
            return {
                ...orderLine,
                product: {
                    ...orderLine.product,
                    serial_numbers,
                    validated_serial_numbers
                },
            }
        });
        state.selectedOrder = { ...order, order_lines: orderLines };
    },

    /**
     * Update the order mechanic comment
     * @param state
     * @param mechanic_comment
     */
    updateOrderMechanicComment(state: { selectedOrder: any }, mechanic_comment: string) {
        state.selectedOrder.mechanic_comment = mechanic_comment;
    },

    /**
     * Set the customer signature Base64
     * @param state
     * @param signatureBase64
     */
    setCustomerSignature(state: { selectedOrder: any }, signatureBase64: any) {
        state.selectedOrder.signature = signatureBase64;
    },


    /**
     * Set QRCodeURL in the selected order for access.
     * @param state
     * @param QRCodeURL
     */
    setQRCodeURL(state: { selectedOrder: any }, QRCodeURL: any) {
        state.selectedOrder.QRCodeURL = QRCodeURL;
    },

    /**
     * Set the product price.
     * @param state
     * @param product
     * @param newPrice
     */
    setUpdateProductPrice(state: { selectedOrder: any }, { line, newPrice }: any) {
        const index = state.selectedOrder.order_lines.findIndex((l: any) => l.id == line.id);
        state.selectedOrder.order_lines[index].price = newPrice;
    },

    /**
     * Adjust the product quantity of a specific product in the order.
     * @param state
     * @param product
     * @param quantity
     */
    setProductQuantity(state: { selectedOrder: any }, { line, quantity }: any) {
        const index = state.selectedOrder.order_lines.findIndex((l: any) => l.id == line.id);
        state.selectedOrder.order_lines[index].quantity = quantity;
    },

    /**
     * Updates a specific order line.
     * @param state
     * @param orderLine
     */
    updateOrderLine(state: { selectedOrder: any }, { orderLine }: any) {
        state.selectedOrder.order_lines = state.selectedOrder.order_lines.map((selectedOrderOrderLine: any) => selectedOrderOrderLine.id === orderLine.id
            ? orderLine
            : selectedOrderOrderLine);
    },

    /**
     * Set the order lines have changed.
     * @param state
     * @param orderLinesHaveChanged
     */
    setOrderLinesHaveChanged(state: { orderLinesHaveChanged: boolean }, { orderLinesHaveChanged }: any) {
        state.orderLinesHaveChanged = orderLinesHaveChanged;
    },

    /**
     * Reset the current selected addable products.
     * @param state
     */
    resetCurrentSelectedAddableProducts(state: any) {
        state.addableProducts.forEach((product: any) => {
            if (product.isSelected) {
                product.isSelected = false;
            }
        })
    },

    /**
     * Remove a specific product from the order.
     * @param state
     * @param productIndex
     */
    removeProductFromOrder(state: { selectedOrder: any }, { line }: any) {
        state.selectedOrder.order_lines = state.selectedOrder.order_lines.filter((orderLine: any) => orderLine.id != line.id);
    },

    /**
     * Set the situation images.
     * @param state
     * @param imageObject
     */
    setSituationImages(state: any, imageObject: any) {
        state.selectedOrder.images.unshift(imageObject);
    },

    /**
     * Add selected products to the order.
     * @param state
     * @param selectedProducts
     */
    addSelectedProductsToOrder(state: any, { selectedProducts }: any) {
        selectedProducts.forEach((product: any) => {
            const foundOrderLine = state.selectedOrder.order_lines.find((currentProduct: any) => currentProduct.product.sku === product.sku && !!product.has_serial_numbers);

            if (!foundOrderLine) {
                const newOrderLine = {
                    price  : product.price,
                    product: {
                        sku: product.sku,
                        id : product.id,
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        has_serial_numbers      : product.has_serial_numbers,
                        serial_numbers          : [],
                        validated_serial_numbers: [],
                        cost_standard           : product.price,
                        name                    : product.name
                    },
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    is_added_by_mechanic: 1,
                    quantity            : 1
                }

                state.selectedOrder.order_lines.push(newOrderLine);
            } else {
                foundOrderLine.quantity++;
            }
        })
    },

    /**
     * Toggle the isSelected a certain product in the addableProductsList.
     * @param state
     * @param productIndex
     */
    toggleSelectionOfCertainProduct(state: any, { productSku }: any) {
        // Find the product in the addableProducts list in the state.addableProductGroups
        const addableProductInAllProductList = state.addableProducts.find((product: any) => product.sku === productSku);

        // Find the product in the addableProductGroup in the state.addableProductGroups
        const foundProductInAddableProductGroup = state.addableProductGroups.find((productGroup: any) => productGroup.products.find((product: any) => product.sku === productSku));

        // If we have found the product in the addableProductGroup, we toggle the isSelected property.
        if (foundProductInAddableProductGroup) {
            foundProductInAddableProductGroup.isSelected = !foundProductInAddableProductGroup.isSelected;
        }

        // If we have the addableProductInAllProductList, we toggle the isSelected property.
        if (addableProductInAllProductList) {
            addableProductInAllProductList.isSelected = !addableProductInAllProductList.isSelected;
        }
    },

    /**
     * Set the searchbar input.
     * @param state
     * @param searchbarInput
     */
    setProductSearchBarInput(state: { productSearchbarInput: string }, { searchbarInput }: any) {
        state.productSearchbarInput = searchbarInput;
    },

    /**
     * Sets the addable products.
     * @param state
     * @param products
     */
    setAddableProducts(state: { addableProducts: any; selectedAddableProductGroups: any }, { products }: any) {
        products = products.map((product: any) => ({ ...product, uid: uuid(), isSelected: false, quantity: 1 }))
        state.addableProducts = products;
        state.selectedAddableProductGroups = [];
    },

    /**
     * Set the addable product groups
     * @param state
     * @param products
     */
    setAddableProductGroups(state: { addableProductGroups: any }, { products }: any) {
        products = products.map((product: any) => ({ ...product, uid: uuid(), isSelected: false, quantity: 1 }))
        state.addableProductGroups = products;
    },

    /**
     * Set selected addable product groups.
     * @param state
     * @param addableProductGroupId
     */
    setSelectedAddableProductGroup(state: { selectedAddableProductGroups: number[] }, { addableProductGroupId }: any) {
        const selectedProductGroups = [...state.selectedAddableProductGroups];

        // check if addableProductGroupId is already in the selectedProductGroups array
        if (selectedProductGroups.includes(addableProductGroupId)) {
            // if yes, remove it
            selectedProductGroups.splice(selectedProductGroups.indexOf(addableProductGroupId), 1);
        } else {
            selectedProductGroups.push(addableProductGroupId);
        }

        state.selectedAddableProductGroups = selectedProductGroups;
    },
};

const getters = {
    /**
     * Return selected order.
     * @param state
     */
    getSelectedOrder(state: { selectedOrder: any }) {
        return state.selectedOrder;
    },

    /**
     * Returns the totalPrice
     * @param state
     */
    getTotalPrice(state: { selectedOrder: any }) {
        let totalPrice = 0;

        const orderProducts = state.selectedOrder.order_lines;

        if (orderProducts && orderProducts.length > 0) {
            orderProducts.forEach((product: any) => {
                totalPrice += (product.price * product.quantity);
            });
        }

        return totalPrice;
    },

    /**
     * Returns an array of order lines which require a serial number.
     * @param state
     */
    getOrderLinesWithSerialNumber(state: { selectedOrder: any }) {
        return state.selectedOrder.order_lines.filter((orderLine: any) => orderLine.product.has_serial_numbers === true);
    },

    /**
     * @param state
     */
    getProductSearchbarInput(state: any) {
        return state.productSearchbarInput;
    },

    /**
     * Return all products that have the isSelected property to true.
     * @param state
     */
    getSelectedAddableProducts(state: any) {
        return state.addableProducts.filter((product: any) => {
            return product.isSelected === true;
        })
    },

    /**
     * Return order lines have changed.
     * @param state
     */
    getOrderLinesHaveChanged(state: any) {
        return state.orderLinesHaveChanged;
    },

    /**
     * Return addable products, filtered by the search bar input.
     * @param state
     */
    getAddableProducts(state: any) {
        let products = state.addableProducts;

        // If a product group is selected, filter the products by the product group.
        if (state.selectedAddableProductGroups.length > 0) {
            // Filter the products and check if their groupId equals one of the groups in selectedAddableProductGroups
            products = products.filter((product: any) => {
                return state.selectedAddableProductGroups.includes(product.groupId);
            });
        }

        // Filter the products by the searchbar input.
        if (state.productSearchbarInput && state.productSearchbarInput.trim() !== '') {
            products = products.filter((product: any) => {
                return (product.name.toLowerCase().indexOf(state.productSearchbarInput.toLowerCase()) > -1) || (product.sku.toLowerCase().indexOf(state.productSearchbarInput.toLowerCase()) > -1);
            })
        }

        // Sort products by times_used property from high to low.
        products.sort((a: any, b: any) => (Number(a.times_used) < Number(b.times_used)) ? 1 : -1);

        return products;
    },

    /**
     * Return addable product groups.
     * @param state
     */
    getAddableProductGroups(state: any) {
        return state.addableProductGroups;
    },

    getSelectedAddableProductGroups(state: any) {
        return state.selectedAddableProductGroups;
    }
};

const actions = {
    /**
     * Set the selected order in the persisted state, also fire a restructure function that handles all necessary data changes.
     * @param context
     * @param selectedOrder
     */
    async setSelectedOrder(context: any, selectedOrder: any) {
        // Set the selected order after it made the restructure changes.
        await context.commit('setSelectedOrder', selectedOrder);
    },

    async setOnlineBankingQRCode(context: any, QRCodeURL: any) {
        await context.commit('setQRCodeURL', QRCodeURL);
    },


    /**
     * Set the customer signature
     * @param context
     * @param signatureBase64
     */
    async setCustomerSignature(context: any, signatureBase64: any) {
        await context.commit('setCustomerSignature', signatureBase64);
    },

    /**
     * Sets the situation images.
     * @param context
     * @param imageObject
     */
    async updateSituationImages(context: any, imageObject: any) {
        await context.commit('setSituationImages', imageObject);
    },

    /**
     * Add the selected products to the order.
     * @param commit
     * @param dispatch
     * @param getters
     */
    async addSelectedProductsToOrder({ commit, dispatch, getters }: any) {
        await dispatch('general/setIsUpdating', { isUpdating: true }, { root: true });
        await commit('addSelectedProductsToOrder', { selectedProducts: getters['getSelectedAddableProducts'] });
        await commit('resetCurrentSelectedAddableProducts');
        await OrderService.updateOrder();
        await dispatch('general/setIsUpdating', { isUpdating: false }, { root: true });
    },

    /**
     * Set the addable products to the store.
     * @param context
     * @param products
     */
    async setAddableProducts(context: any, products: Array<object>) {
        await context.commit('setAddableProducts', { products });
    },

    /**
     * Set the addable product groups to the store.
     * @param context
     * @param products
     */
    async setAddableProductGroups(context: any, products: Array<object>) {
        await context.commit('setAddableProductGroups', { products });
    },
};

export const order = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
