
    import { IonButton, IonContent, IonPage } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import AuthService from '../services/auth.service';

    export default defineComponent({
        name: 'Home',
        components: {
            IonContent,
            IonPage,
            IonButton,
        },
        methods: {
            /**
             * Microsoft azure login
             */
            useMicrosoftLogin() {
                // Fetch azure login url from endpoint.
                AuthService.getAzureLoginUrl().then((url) => {
                    location.href = url;
                });
            },
        },
    });
