import OrderService from "@/services/order.service";
import ToastService from "@/services/toast.service";
import { ref } from "vue";
import router from "@/router";

const intervalList: Array<number> = [];
let interval: any;

export default function () {
    const loadingQRCode = ref(false);

    const stopInterval = () => {
        clearInterval(interval);
    };

    const startInterval = () => {
        interval = window.setInterval(async () => {
            const paymentStatus = await OrderService.keepCheckingForOrderStatus();

            switch (paymentStatus) {
                case('paid'):
                    await clearInterval(interval);
                    await router.push({ name: 'PaymentSuccess' });
                    break;

                case('draft'):
                    loadingQRCode.value = true;
                    await ToastService.presentToast('De betaling is mislukt, we hebben een nieuwe QR-code gegenereerd, laat de klant deze opnieuw scannen.', 4500, 'danger', 'top');
                    await OrderService.sendPaymentMethod('iD');
                    break;

                case('canceled'):
                    loadingQRCode.value = true;
                    await ToastService.presentToast('De betaling is geannuleerd, we hebben een nieuwe QR-code gegenereerd, laat de klant deze opnieuw scannen.', 4500, 'danger', 'top');
                    await OrderService.sendPaymentMethod('iD');
                    break;

                case('expired'):
                    loadingQRCode.value = true;
                    await ToastService.presentToast('De QR-code is verlopen, we hebben een nieuwe QR-code gegenereerd, laat de klant deze opnieuw scannen.', 4500, 'danger', 'top');
                    await OrderService.sendPaymentMethod('iD');
                    break;
            }

            loadingQRCode.value = false;
        }, 5000);

        intervalList.push(interval);
    };

    return { interval, startInterval, stopInterval, loadingQRCode };
}