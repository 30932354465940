import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!

  return (_openBlock(), _createBlock(_component_ion_refresher, {
    class: "pull-to-refresh",
    slot: "fixed",
    onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doRefresh($event))),
    "snapback-duration": "100ms"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_refresher_content, { "pulling-icon": _ctx.chevronDownCircleOutline }, null, 8, ["pulling-icon"])
    ]),
    _: 1
  }))
}