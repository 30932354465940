import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    breakpoints: [0,0, 0.75, 1],
    "initial-breakpoint": 0.75,
    "is-open": _ctx.isOpen,
    "swipe-to-close": true,
    class: "sheet-modal",
    onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = false))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        class: "modal-header ion-no-border",
        mode: "ios"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "body")
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["breakpoints", "initial-breakpoint", "is-open"]))
}