import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_base_loader = _resolveComponent("base-loader")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    class: _normalizeClass([_ctx.size, "card-modal"]),
    "is-open": _ctx.isOpen,
    "presenting-element": _ctx.topOutlet,
    "swipe-to-close": true,
    animated: "true",
    mode: "ios",
    onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = false, _ctx.viewDidEnter = false)),
    onDidPresent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.viewDidEnter = true))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        class: "modal-header ion-no-border",
        mode: "ios"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _renderSlot(_ctx.$slots, "header-content")
        ]),
        _: 3
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_ctx.viewDidEnter)
                ? _renderSlot(_ctx.$slots, "body", { key: 0 })
                : (_openBlock(), _createBlock(_component_base_loader, {
                    key: 1,
                    type: 'medium'
                  }))
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["class", "is-open", "presenting-element"]))
}