
    import { defineComponent } from 'vue';
    import QuantityIncreaser from '@/components/QuantityIncreaser.vue';
    import SheetModal from "@/components/SheetModal.vue";
    import CurrencyInput from "@/components/CurrencyInput.vue";
    import { IonButton } from '@ionic/vue';
    import { store } from '@/store';

    export default defineComponent({
        name      : 'ProductListItem',
        props     : {
            index     : {
                type    : Number,
                required: true
            },
            orderLine : {
                type    : Object,
                required: true
            },
            isFinished: {
                type: [Number, Boolean],
            }
        },
        components: {
            SheetModal,
            QuantityIncreaser,
            CurrencyInput,
            IonButton
        },
        data() {
            return {
                newPrice: null
            };
        },
        computed: {
            totalProductPrice(): any {
                return this.orderLine.price;
            }
        },
        methods : {
            setNewPrice(line: object, newPrice: number) {
                if (this.$refs.productModal && !this.isFinished) {
                    (this.$refs.productModal as any).isOpen = false;
                }
                store.commit('order/setUpdateProductPrice', {
                    line,
                    newPrice
                });
            },
            openPriceSheetModal() {
                if (this.$refs.productModal && !this.isFinished) {
                    (this.$refs.productModal as any).isOpen = true;
                }
                this.newPrice = null;
            },
            priceFormatting(price: any) {
                return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', currencySign: 'accounting' }).format(price);
            },
        },
    });
