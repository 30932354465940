
    import { defineComponent } from 'vue';
    import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
    import BasePanel from '@/components/BasePanel.vue';
    import OrderService from '@/services/order.service';
    import useMolliePayment from '@/composables/useMolliePayment';
    import { store } from "@/store";

    export default defineComponent({
        name      : 'PaymentSuccess',
        components: {
            IonContent,
            BasePanel,
            IonPage,
            IonTitle,
            IonButtons,
            IonBackButton,
            IonHeader,
            IonToolbar,
        },
        data() {
            return {
                BackIcon: require('@/assets/images/icons/back.svg'),
            };
        },
        setup() {
            const { interval } = useMolliePayment();

            clearInterval(interval);

            return {
                interval,
            };
        },
        methods : {
            backToPlanning() {
                OrderService.getAllOrders().then(() => {
                    this.$router.push({
                        name: 'Planning',
                    });
                });
            },
        },
        computed: {
            totalPrice() {
                return store.getters['order/getTotalPrice'];
            }
        },

        ionViewWillLeave() {
            clearInterval(this.interval);
        },
    });
