
    import { defineComponent } from 'vue';
    import { IonContent, IonHeader, IonItem, IonItemGroup, IonLabel, IonPage, IonRippleEffect, IonTitle, IonToolbar, IonSegment, IonSegmentButton } from '@ionic/vue';
    import AuthService from '@/./services/auth.service';
    import { store } from '@/store';
    import PullToRefresh from '@/components/PullToRefresh.vue';
    import OrderService from '../services/order.service';

    export default defineComponent({
        name: 'Planning',
        data() {
            return {
                finished: false,
            };
        },
        components: {
            PullToRefresh,
            IonContent,
            IonPage,
            IonTitle,
            IonItemGroup,
            IonHeader,
            IonToolbar,
            IonLabel,
            IonRippleEffect,
            IonItem,
            IonSegment,
            IonSegmentButton,
        },
        methods: {
            /**
             * Trigger a chain that logs out the user from the application.
             */
            logout() {
                AuthService.logout().then(() => {
                    this.$router.push({ name: 'Login' });
                });
            },

            /**
             * Fetches orders with the given state 'finished' that is either true or false.
             * */
            async fetchAllOrders(finished: boolean) {
                store.commit('planning/setSegmentState', finished);
                store.commit('planning/setPlanning', []);
                await OrderService.getAllOrders();
            },

            /**
             * Move to the order detail page.
             * @param item
             */
            moveToOrderDetail(item: any) {
                OrderService.getOrderById(item.id).finally(() => {
                    OrderService.fetchAddableProductGroups();
                    this.$router.push({ name: 'OrderDetail', params: { id: item.id } });
                });
            },

            reformatTimeslot(value: any) {
                return value.split(' - ');
            },
        },
        created() {
            // Fetch the orders.
            OrderService.getAllOrders();

            // Fetch the user information
            AuthService.getUserInformation();
        },
        computed: {
            planning() {
                return store.getters['planning/getPlanning'];
            },

            user() {
                return store.getters['general/getUser'];
            },

            segmentState() {
                return store.getters['planning/getSegmentState'];
            },

            activeIntervals() {
                return store.getters['order/getActiveIntervals'];
            },
        },
    });
