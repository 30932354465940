
    import { defineComponent } from 'vue';
    import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
    import BasePanel from '@/components/BasePanel.vue';
    import OrderService from "@/services/order.service";
    import BaseLoader from "@/components/BaseLoader.vue";

    export default defineComponent({
        name      : 'PayCash',
        components: {
            BaseLoader,
            IonContent,
            BasePanel,
            IonPage,
            IonTitle,
            IonButtons,
            IonBackButton,
            IonHeader,
            IonToolbar,
        },
        data() {
            return {
                BackIcon: require('@/assets/images/icons/back.svg'),
                loading : false as any,
            };
        },
        methods: {
            async toPayCashSuccess() {
                this.loading = true;
                this.loading = await OrderService.sendPaymentMethod('00');
            },
        }
    });
