const state = {
    planning    : [],
    segmentState: false,
};

const mutations = {
    /**
     * Update the segment state.
     * @param state
     * @param boolean
     */
    setSegmentState(state: { segmentState: boolean }, boolean: boolean) {
        state.segmentState = boolean;
    },

    /**
     * Set the planning.
     * @param state
     * @param planning
     */
    setPlanning(state: { planning: Array<object> }, planning: Array<object>) {
        state.planning = planning;
    },
};

const getters = {
    /**
     * Returns the planning array.
     * @param state
     */
    getPlanning(state: { planning: Array<object> }) {
        return state.planning;
    },

    /**
     * Return the segment state
     * @param state
     */
    getSegmentState(state: { segmentState: boolean }) {
        return state.segmentState;
    }
};

export const planning = {
    namespaced: true,
    state,
    getters,
    mutations
};
