
    import { defineComponent } from 'vue';
    import { IonRippleEffect } from '@ionic/vue';

    export default defineComponent({
        name: 'CtaButton',
        components: {
            IonRippleEffect,
        },
    });
