
    import { defineComponent } from 'vue';
    import { IonRippleEffect } from '@ionic/vue';
    import { store } from '@/store';

    export default defineComponent({
        name: 'QuantityIncreaser',
        components: {
            IonRippleEffect,
        },
        data() {
            return {
                inputValue: 0,
                isVisible: false,
                focus: false,
                animationTimeout: 3000,
                myToid: 0,
            };
        },
        props: {
            productIndex: {
                type: Number,
                required: true,
            },
            product: {
                type: Object,
                required: true,
            },
            isFinished: {
                type: [Number, Boolean],
            },
        },
        methods: {
            /**
             * (Re)set a timer of 3 seconds to display the input and decreaser.
             * @param e
             */
            controlQuantityVisibility(e: any) {
                e.preventDefault();

                this.isVisible = true;

                clearTimeout(this.myToid);

                this.myToid = setTimeout(() => {
                    this.isVisible = false;
                }, this.animationTimeout);
            },
            /**
             * Remove the product from the order.
             * @param product
             */
            async removeProductFromOrder(line: any) {
                this.isVisible = false;
                store.commit('order/removeProductFromOrder', { line });
            },

            /**
             * Set the quantity of the product.
             */
            setQuantityByCustomInput(e: any, product: any, quantity: number) {
                this.controlQuantityVisibility(e);

                if (this.inputValue < 0) {
                    this.inputValue = 0;
                }

                if (quantity > 0 || isNaN(quantity)) {
                    store.commit('order/setProductQuantity', {
                        product,
                        quantity,
                    });
                }
            },

            /**
             * Set the quantity of the product.
             */
            setQuantity(e: any, line: any, quantity: number) {
                if (!this.isVisible) {
                    quantity--;
                }

                this.controlQuantityVisibility(e);

                if (quantity < 1 || isNaN(quantity)) {
                    this.removeProductFromOrder(line);
                } else {
                    store.commit('order/setProductQuantity', {
                        line,
                        quantity,
                    });
                }
            },
        },
        computed: {
            productQuantity() {
                return this.product.quantity;
            },
            productIncrease() {
                if (this.isVisible) {
                    return '';
                }
                if (!this.isVisible && this.productQuantity > 0) {
                    return 'is-active';
                }
                return '';
            },
        },
    });
