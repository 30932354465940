const state = {
    headers    : { token: '' },
    accessToken: '',
    user       : {}
};

const mutations = {
    /**
     * Set the azure access token
     * @param state
     * @param accessToken
     */
    setAzureAccessToken(state: { accessToken: string }, accessToken: string) {
        state.accessToken = accessToken;
    },

    /**
     * Sets the given user information
     * @param state
     * @param user
     */
    setUserInformation(state: { user: object }, user: object) {
        state.user = user;
    },

    /**
     * Set the authorization header token
     * @param state
     * @param accessToken
     */
    setAuthHeaderToken(state: { headers: any }, accessToken: string) {
        state.headers.token = accessToken
    },

    /**
     * Set the azure access to null.
     * @param state
     */
    removeAzureAccessToken(state: { accessToken: any }) {
        state.accessToken = null;
    }
};

const getters = {
    /**
     * Get the accessToken
     * @param state
     */
    getAzureAccessToken: (state: { accessToken: string }) => {
        return state.accessToken;
    },

    /**
     * Returns the authorization headers.
     * @param state
     */
    getAuthHeaders: (state: { headers: object }) => {
        return state.headers;
    },

    /**
     * Returns if the a user is a planner.
     * @param state
     */
    getUserIsPlanner: (state: { user: any }) => {
        // Check if the roles array in the user contains one that is planner.
        // And is not the owner of the given appointment
        return state.user.roles.some((role: any) => role.name === 'planner');
    },

    /**
     * Returns the user id
     * @param state
     */
    getUserId(state: { user: any }) {
        return state.user.id;
    },
};

const actions = {
    /**
     * Set the azure access token
     * @param context
     * @param accessToken
     */
    setAzureAccessToken: async (context: any, accessToken: string) => {
        await context.commit('setAzureAccessToken', accessToken);
        await context.commit('setAuthHeaderToken', accessToken);
    },


    /**
     * Remove the accessToken.
     * @param context
     */
    logout: async (context: any) => {
        await context.commit('removeAzureAccessToken');
    }
};

export const auth = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
