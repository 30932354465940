
import {defineComponent} from 'vue';
import {IonContent, IonHeader, IonModal, IonTitle, IonToolbar} from '@ionic/vue';
import BaseLoader from '@/components/BaseLoader.vue';

export default defineComponent({
    name: 'CardModal',
    components: {BaseLoader, IonModal, IonContent, IonHeader, IonToolbar, IonTitle},
    props: {
        size: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            viewDidEnter: false,
            isOpen: false,
            topOutlet: document.querySelector('.modal-trigger-outlet'),
        };
    },
});
