import axios from "axios";
import { store } from "@/store";
import ToastService from "@/services/toast.service";
import router from "@/router";
import OrderService from "@/services/order.service";

export default {
    /**
     * Retrieve the azure login url from the API.
     */
    getAzureLoginUrl: async function () {
        let loginUrl = '';

        await axios.get(process.env.VUE_APP_API_URL + '/azure/login?type=mechanic' + (process.env.VUE_APP_REDIRECT_URL || '')).then((res) => {
            // Response is the url where we will sent the mechanic to.
            if (res.data) {
                loginUrl = res.data;
                this.setAccessToken(loginUrl);
            }
        })

        return loginUrl;
    },

    /**
     * Retrieves the logged users information
     */
    getUserInformation: async function () {
        await axios.get(process.env.VUE_APP_API_URL + '/mechanic/employees/me', {
            headers: store.getters['auth/getAuthHeaders']
        }).then((res) => {
            const user = res.data.data;
            store.commit('auth/setUserInformation', user);
        }).catch((error) => {
            if (error.response) {
                this.checkForErrors(error)
            }
        });
    },

    /**
     * Logout from the application.
     */
    async logout() {
        await axios.get(process.env.VUE_APP_API_URL + '/azure/logout', { headers: store.getters['auth/getAuthHeaders'] }).then((res) => {
            if (res) {
                // Remove the auth tokens.
                store.dispatch('auth/logout');
            }
        });
    },

    /**
     * Set the access token in the store.
     * @param token
     */
    setAccessToken: async function (token: string) {
        await store.dispatch("auth/setAzureAccessToken", token);
        await OrderService.fetchAddableProductGroups();
    },

    /**
     * Checks catch response errors
     * @param error
     */
    checkForErrors: async function (error: any) {
        if (error.response && error.response.status) {
            // Something went wrong on server
            if (error.response.status === 500) {
                await ToastService.presentArrayOfToasts(['Er ging iets fout op de server, probeer het later opnieuw', error.message], 3000, 'danger');
            }

            if (error.response.status === 422) {
                let errors = null;

                if (error.response.data.errors) {
                    errors = error.response.data.errors;
                }

                const messages: string[] = ['Er ging iets fout op de server'];

                if (errors) {
                    Object.values(errors).forEach((error: any) => {
                        error.forEach((errorMessage: string) => {
                            messages.push(errorMessage);
                        });
                    });
                }

                await ToastService.presentArrayOfToasts(messages, 3000, 'danger');
            }

            // Authentication has expired.
            if (error.response.status === 403) {
                await axios.post(process.env.VUE_APP_API_URL + `/azure/token/validate`, { validateToken: store.getters['getAzureAccessToken'] }, {
                    headers: store.getters['auth/getAuthHeaders']
                }).then(() => {
                    ToastService.presentToast('De actie kan niet worden uitgevoerd.', 3000, 'danger');
                    router.push({ name: 'Home' });
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 403) {
                            store.commit('auth/setAuthHeaderToken', null);
                            store.commit('auth/setAzureAccessToken', null);
                            ToastService.presentToast('Authenticatie token is verlopen, log opnieuw in', 3000, 'danger');
                            router.push({ name: 'Login' });
                        }
                    }
                });
            }
        }
    }
}
