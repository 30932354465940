
    import { StreamBarcodeReader } from 'vue-barcode-reader';
    import { defineComponent } from 'vue';
    import { store } from '@/store';
    import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonRippleEffect, IonTitle, IonToolbar } from '@ionic/vue';
    import BasePanel from '@/components/BasePanel.vue';
    import OrderService from '@/services/order.service';
    import ToastService from '@/services/toast.service';
    import BaseLoader from '@/components/BaseLoader.vue';
    import debounce from 'lodash/debounce';
    import router from "@/router";

    export default defineComponent({
        name      : 'ProductSerialNumber',
        components: {
            BaseLoader,
            BasePanel,
            IonBackButton,
            IonButtons,
            IonContent,
            IonRippleEffect,
            IonHeader,
            IonPage,
            IonTitle,
            IonToolbar,
            StreamBarcodeReader,
        },
        data() {
            return {
                BackIcon            : require('@/assets/images/icons/back.svg'),
                barcodeScannerLoaded: false,
                showBarcodeScanner  : false,
                scanBarcodeFor      : {
                    index   : 0,
                    quantity: 0,
                },
            };
        },
        mounted() {
            // Only applies when this order is already finished.
            if (this.order.is_finished) {
                const mappedSerialNumbersOrderLine = [...this.order.order_lines];

                // For each orderLine in the order.
                mappedSerialNumbersOrderLine.forEach((orderLine: any) => {
                    // Create an array in the 'product' object in the orderLine, that contains the string values of the serial numbers.
                    orderLine.product.serial_numbers = orderLine.serial_numbers.reduce((acc: any, serialNumber: any) => {
                        acc.push(serialNumber.serial_number);

                        return acc;
                    }, []);


                    // Update the order line.
                    store.commit('order/updateOrderLine', { orderLine: orderLine });
                })
            }
        },
        computed: {
            isUpdating() {
                return store.getters['general/getIsUpdating'];
            },

            order() {
                return store.getters['order/getSelectedOrder'];
            },

            orderLines() {
                return store.getters['order/getOrderLinesWithSerialNumber'];
            },
        },
        methods : {
            checkOrderLineProductConditions(orderLineProduct: any, position: number) {
                return orderLineProduct.serial_numbers[position]
                       && orderLineProduct.serial_numbers[position].length > 0
                       && orderLineProduct.validated_serial_numbers[position]
                       && !orderLineProduct.validated_serial_numbers[position].isValid
                       && orderLineProduct.validated_serial_numbers[position].loading === false;
            },

            onLoaded() {
                this.barcodeScannerLoaded = true;
            },

            async onDecode(result: any) {
                await this.updateOrderLineSerialNumber(result, this.scanBarcodeFor.index, this.scanBarcodeFor.quantity);
                this.closeBarcodeScanner();
            },

            closeBarcodeScanner() {
                this.showBarcodeScanner = false;
                this.barcodeScannerLoaded = false;
            },

            /**
             * Update a given orderline.
             * @param orderLine
             */
            updateOrderLine(orderLine: any) {
                store.commit('order/updateOrderLine', orderLine);
            },

            /**
             * Open the barcde scanner
             */
            openBarcodeScanner(index: number, quantity: any) {
                // TODO: open the scanner
                this.showBarcodeScanner = true;

                this.scanBarcodeFor = {
                    index,
                    quantity,
                };
            },


            /**
             * Updates a specific order line on input.
             * @param value
             * @param orderLineIndex
             * @param quantity
             */
            async updateOrderLineSerialNumber(value: any, orderLineIndex: number, quantity: number) {
                const updatedOrderLine = { ...this.orderLines[orderLineIndex] };

                // If the event target value is not empty, then update the serial number.
                if (value.length > 0) {
                    updatedOrderLine.product.serial_numbers[quantity] = value;

                    updatedOrderLine.product.validated_serial_numbers[quantity] = {
                        value,
                        isValid: null,
                        loading: true,
                        message: 'Dit serienummer is onbekend. Typ het nummer goed over of scan de barcode.',
                    };

                    // Checks if the order line does not contain duplicates.
                    const doesNotContainDuplicates = updatedOrderLine.product.serial_numbers.length === new Set(updatedOrderLine.product.serial_numbers).size;

                    if (!doesNotContainDuplicates) {
                        updatedOrderLine.product.validated_serial_numbers[quantity].message = 'Voer een uniek serienummer in voor ieder product';
                        updatedOrderLine.product.validated_serial_numbers[quantity].isValid = false;
                        updatedOrderLine.product.validated_serial_numbers[quantity].loading = false;
                        await ToastService.presentToast('Voer een uniek serienummer in voor ieder product', 2500, 'danger');
                        return;
                    }

                    // Always set loading on true if we get here, the user is updating this particular serial number.
                    updatedOrderLine.product.validated_serial_numbers[quantity].loading = true;

                    if (value.length >= 7) {
                        await this.checkIfSerialNumberIsValid(updatedOrderLine, updatedOrderLine.product.serial_numbers[quantity], quantity);
                    } else {
                        // loading false
                        updatedOrderLine.product.validated_serial_numbers[quantity].loading = false;
                        updatedOrderLine.product.validated_serial_numbers[quantity].isValid = false;
                        updatedOrderLine.product.validated_serial_numbers[quantity].message = 'Serienummer moet minstens 7 karakters lang zijn.';
                    }

                    return;
                }
                // Else set the value to null
                updatedOrderLine.product.serial_numbers[quantity] = null;
                updatedOrderLine.product.validated_serial_numbers[quantity] = {
                    value  : null,
                    isValid: false,
                    loading: false,
                };

                // Update the order line.
                store.commit('order/updateOrderLine', { orderLine: updatedOrderLine });
            },

            /**
             * Checks if a serial number is valid.
             * @param product
             * @param serialNumber
             */
            checkIfSerialNumberIsValid: debounce(async (orderLine: any, serialNumber: string, quantity: number) => {
                // Checks if a serial number matches one registered for the product.
                const message = await OrderService.checkProductSerialNumber(orderLine.product, serialNumber);

                // If we have a message, this means the serial number is not valid.
                if (message) {
                    // TODO: Uncomment if we want toast.
                    // await ToastService.presentToast(message, 2500, 'danger');

                    // Set the loading indicator back to false.
                    orderLine.product.validated_serial_numbers[quantity].loading = false;

                    store.commit('order/updateOrderLine', { orderLine });
                    return false;
                }

                // Update the order line.
                orderLine.product.validated_serial_numbers[quantity].isValid = true;
                orderLine.product.validated_serial_numbers[quantity].loading = false;
                store.commit('order/updateOrderLine', { orderLine });
                return true;
            }, 1000),

            /**
             * Triggered when user wants to go to the next step.
             */
            toNextStep() {
                // Checks if all orderLines have the required amount of serial numbers.
                const isEverySerialNumberValid = this.orderLines.every((orderLine: any) => orderLine.product.validated_serial_numbers.every((validatedSerialNumbers: any) => validatedSerialNumbers.isValid === true));

                // If they do, update the order and go to the agreement page.
                if (isEverySerialNumberValid) {
                    // TODO: Call API Endpoint to send the order_lines with serial numbers to the backend.
                    // If the response is OK then we can call the updateOrder and push the user to the OrderAgreement page.
                    OrderService.addSerialNumbers();
                } else {
                    ToastService.presentToast('Niet alle serienummers zijn correct ingevuld.', 2500, 'danger');
                }
            },

            toNextStepFinished() {
                router.push({ name: 'OrderAgreement', params: { id: store.getters['order/getSelectedOrder'].id } });
            }
        },
    });
