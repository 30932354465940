import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "panel" }
const _hoisted_2 = {
  key: 0,
  class: "content"
}
const _hoisted_3 = {
  key: 1,
  class: "cta-holder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.contentSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "content")
        ]))
      : _createCommentVNode("", true),
    (_ctx.ctaSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "cta")
        ]))
      : _createCommentVNode("", true)
  ]))
}