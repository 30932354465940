<template>
    <div class="loader-container">
        <div class="loader" :class="type" />
    </div>
</template>

<script>
    export default {
        name: 'BaseLoader',
        props: {
            type: {
                type: String,
                required: false,
                default: 'small',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .loader-container {
        position        : absolute;
        z-index         : index($elements, popover);
        left            : 0;
        display         : flex;
        align-items     : center;
        justify-content : center;
        width           : 100%;
        height          : 100%;

        .loader {
            border        : vw(3px) solid #F3F5FC;
            border-top    : vw(3px) solid var(--color-secondary);
            border-radius : 50%;
            animation     : spin 1s linear infinite;

            &.small {
                width  : vw(24px);
                height : vw(24px);
            }

            &.medium {
                width  : vw(40px);
                height : vw(40px);
            }

            &.large {
                width  : vw(56px);
                height : vw(56px);
            }
        }

        @keyframes spin {
            0% {
                transform : rotate(0deg);
            }
            100% {
                transform : rotate(360deg);
            }
        }
    }
</style>
