
    import { IonRefresher, IonRefresherContent } from '@ionic/vue';
    import { chevronDownCircleOutline } from 'ionicons/icons';
    import { defineComponent } from 'vue';
    import OrderService from '../services/order.service';

    export default defineComponent({
        name: 'PullToRefresh',
        components: { IonRefresher, IonRefresherContent },
        setup() {
            const doRefresh = (event: any) => {
                setTimeout(() => {
                    OrderService.getAllOrders().finally(() => {
                        event.target.complete();
                    });
                }, 500);
            };
            return { chevronDownCircleOutline, doRefresh };
        },
    });
