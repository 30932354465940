import axios from "axios";
import { store } from "@/store";
import AuthService from '@/services/auth.service';
import router from "@/router";
import ToastService from "@/services/toast.service";


export default {
    /**
     * Retrieve the azure login url from the API.
     */
    getAllOrders: async function () {
        await axios.get(process.env.VUE_APP_API_URL + `/mechanic/appointments?finished=${store.getters['planning/getSegmentState']}`, {
            headers: store.getters['auth/getAuthHeaders']
        }).then((res) => {
            const planning = res.data.data;

            // If we retrieved a planning, set the planning in store.
            if (planning) {
                store.commit('planning/setPlanning', planning);
            }
        }).catch((error) => {
            if (error.response) {
                AuthService.checkForErrors(error)
            }
        });
    },

    /**
     * Checks if a product serial number is correct.
     * @param product
     * @param serialNumber
     */
    checkProductSerialNumber: async function (product: any, serialNumber: string) {
        let returnVal = null;
        await axios.post(process.env.VUE_APP_API_URL + '/mechanic/products/' + product.id + '/check-serial-number', {
            serialNumber: serialNumber
        }, {
            headers: store.getters['auth/getAuthHeaders'],
        }).then().catch((error) => {
            if (error.response) {
                AuthService.checkForErrors(error);

                if (error.response.data.errors.serialNumber[0]) {
                    returnVal = error.response.data.errors.serialNumber[0];
                }
            }
        }).finally(async () => {
            await store.dispatch('general/setIsUpdating', { isUpdating: false }, { root: true });
        });

        return returnVal;
    },

    /**
     * Updates the e-mail address of a customer.
     * @param id
     * @param email
     */
    updateCustomerEmailAddress: async function (id: number, email: string) {
        await store.dispatch('general/setIsUpdating', { isUpdating: true }, { root: true });
        await axios.post(process.env.VUE_APP_API_URL + '/mechanic/appointments/' + id + '/email', { email: email }, {
            headers: store.getters['auth/getAuthHeaders'],
        }).then(() => {
            this.getOrderById(id);
        }).catch((error) => {
            if (error.response) {
                AuthService.checkForErrors(error)
            }
        }).finally(async () => {
            await store.dispatch('general/setIsUpdating', { isUpdating: false }, { root: true });
        });

    },

    /**
     * Retrieve an order by given id.
     * @param id
     */
    getOrderById: async function (id: number) {
        await axios.get(process.env.VUE_APP_API_URL + '/mechanic/appointments/' + id, {
            headers: store.getters['auth/getAuthHeaders']
        }).then((res) => {
            // Dispatch an action to set the current selected order to be the fetched one.
            const order = res.data.data;

            order.images = order.images.map((image: any) => ({ ...image, uploadedAt: image.uploaded_at }));

            store.dispatch('order/setSelectedOrder', order);
        }).catch((error) => {
            if (error.response) {
                AuthService.checkForErrors(error)
            }
        });
    },

    /**
     * Fetch all addable products.
     */
    fetchAddableProducts: async function () {
        await axios.get(process.env.VUE_APP_API_URL + '/products', {
            headers: store.getters['auth/getAuthHeaders']
        }).then((res) => {
            store.dispatch('order/setAddableProducts', res.data.data);
        }).catch((error) => {
            if (error.response) {
                AuthService.checkForErrors(error)
            }
        });
    },

    /**
     * Fetch all addable product groups.
     */
    fetchAddableProductGroups: async function () {
        await axios.get(process.env.VUE_APP_API_URL + '/mechanic/product-groups', {
            headers: store.getters['auth/getAuthHeaders']
        }).then((res) => {

            const productGroups = res.data.data;

            const productsWithoutGroups = productGroups.reduce((acc: any, group: any) => {
                const groupProducts = group.products.map((product: any) => ({ ...product, groupDescription: group.description, groupId: group.id }));

                acc.push(...groupProducts);

                return acc;
            }, []);

            store.dispatch('order/setAddableProducts', productsWithoutGroups);
            store.dispatch('order/setAddableProductGroups', res.data.data);
        }).catch((error) => {
            if (error.response) {
                AuthService.checkForErrors(error)
            }
        });
    },

    /**
     * Update the selected order.
     */
    updateOrder: async function () {
        return await axios.post(process.env.VUE_APP_API_URL + `/mechanic/appointments/${store.getters['order/getSelectedOrder'].id}`, store.getters['order/getSelectedOrder'], {
            headers: store.getters['auth/getAuthHeaders']
        }).then((res) => {
            const order = res.data.data;

            order.images = order.images.map((image: any) => ({ ...image, uploadedAt: image.uploaded_at }));

            store.dispatch('order/setSelectedOrder', order);

            // Order is updated.
            return true;
        }).catch((error) => {
            if (error.response) {
                AuthService.checkForErrors(error)
            }

            return false;
        });
    },

    /**
     * Formats to an array of error messages
     * @param errorArray
     */
    formatSerialNumberErrors(errorArray: object) {
        const arrayOfMessages = [] as any;

        if (errorArray) {
            // Loop through the error object.
            Object.values(errorArray).forEach((errorArray: any) => {

                // Loop through the errorArray
                errorArray.forEach((error: any) => {

                    // If the array does not exist in the array messages yet
                    if (!arrayOfMessages.find((errorMessage: any) => errorMessage === error)) {
                        arrayOfMessages.push(error);
                    }
                });
            });
        }

        // Return the array of messages.
        return arrayOfMessages;
    },

    /**
     * Add serial numbers to the product in Exact Online
     */
    addSerialNumbers: async function () {
        await store.dispatch('general/setIsUpdating', { isUpdating: true }, { root: true });
        await axios.post(process.env.VUE_APP_API_URL + `/mechanic/appointments/${store.getters['order/getSelectedOrder'].id}/serial-numbers`, store.getters['order/getSelectedOrder'].order_lines, {
            headers: store.getters['auth/getAuthHeaders']
        }).then(() => {
            // Order is updated.
            router.push({ name: 'OrderAgreement', params: { id: store.getters['order/getSelectedOrder'].id } });
        }).catch((error) => {
            if (error.response) {
                AuthService.checkForErrors(error)
            }
        }).finally(() => {
            store.dispatch('general/setIsUpdating', { isUpdating: false }, { root: true });
        });
    },

    /**
     * Sends the payment method to the API.
     * @param method
     */
    sendPaymentMethod: async function (method: string) {
        try {
            await axios.post(process.env.VUE_APP_API_URL + `/mechanic/appointments/${store.getters['order/getSelectedOrder'].id}/checkout`, { 'payment_method': method }, {
                headers: store.getters['auth/getAuthHeaders']
            }).then((res) => {
                if (res.data.qr_code) {
                    store.dispatch('order/setOnlineBankingQRCode', res.data.qr_code);
                }

                if (!res.data.qr_code && method === '14') {
                    router.push({ name: 'PayLaterSuccess' });
                } else if (!res.data.qr_code) {
                    router.push({ name: 'PaymentSuccess' });
                }
            }).catch((error) => {
                if (error.response) {
                    AuthService.checkForErrors(error)
                }
            }).finally(() => {
                return false;
            });
        } catch (e) {
            return false;
        }
    },

    /**
     * Check for an order status update every 5 seconds.
     */
    async keepCheckingForOrderStatus() {
        const selectedOrder = store.getters['order/getSelectedOrder'];
        let paymentStatus = null as any;

        await axios.get(process.env.VUE_APP_API_URL + `/mechanic/appointments/${selectedOrder.id}/mollie-status`, {
            headers: store.getters['auth/getAuthHeaders']
        }).then((res) => {
            // Order is updated.
            paymentStatus = res.data.status;
        }).catch((error) => {
            if (error.response) {
                AuthService.checkForErrors(error)
            }
        });

        return paymentStatus;
    },

    /**
     * Update the customer signature
     */
    sendCustomerSignature: async function (signatureBase64: any) {
        await store.dispatch('order/setCustomerSignature', signatureBase64);
        await this.updateOrder();
    },

    async updateAllImagesAndFetchOrder(images: any[]) {
        try {
            // Generate an array of promises for each image update
            const updatePromises = images.map(image => this.updateSituationImages(image.src));

            // Wait for all image updates to complete
            await Promise.all(updatePromises);

            // Once all images are updated, fetch the order by ID
            const orderId = store.getters['order/getSelectedOrder'].id;

            // Optional: return or do something with the order
            await this.getOrderById(orderId);
            await ToastService.presentToast("Alle foto's zijn opgeslagen", 2500, 'success');
        } catch (error) {
            await ToastService.presentToast("Er is een fout opgetreden bij het opslaan van de foto's", 2500, 'danger');
            throw error; // Rethrow or handle as needed
        }
    },

    /**
     * Update situationImages on the selected order.
     * @param image
     */
    updateSituationImages: async function (image: any) {
        await axios.post(process.env.VUE_APP_API_URL + `/mechanic/appointments/${store.getters['order/getSelectedOrder'].id}/images`, { image: image }, {
            headers: store.getters['auth/getAuthHeaders']
        }).then(() => {
            image.isNewImage = false;
        }).catch((error) => {
            if (error.response) {
                AuthService.checkForErrors(error)
            }
        });
    },
}
