import { createStore } from 'vuex';
import { auth } from "./auth.store";
import { planning } from "./planning.store";
import { order } from "./order.store";
import { general } from "@/store/general.store";
import createPersistedState from "vuex-persistedstate";

export const store = createStore({
    plugins  : [
        createPersistedState(
            {
                key: 'monteur-app'
            }
        )
    ],
    modules  : {
        auth,
        planning,
        order,
        general
    },
})