
    import { defineComponent } from 'vue';
    import { store } from '@/store';
    import BasePanel from '@/components/BasePanel.vue';
    import OrderService from '@/services/order.service';
    import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
    import CtaButton from '@/components/CtaButton.vue';

    export default defineComponent({
        name: 'Checkout',
        components: {
            CtaButton,
            BasePanel,
            IonContent,
            IonPage,
            IonTitle,
            IonButtons,
            IonBackButton,
            IonHeader,
            IonToolbar,
        },
        data() {
            return {
                BackIcon: require('@/assets/images/icons/back.svg'),
            };
        },
        computed: {
            totalPrice() {
                const totalPrice = store.getters['order/getTotalPrice'];

                return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', currencySign: 'accounting' }).format(totalPrice);
            },
        },
        methods: {
            toOnlineBanking(method: string) {
                this.setPaymentMethod(method).then(() => {
                    this.$router.push({
                        name: 'OnlineBanking',
                    });
                });
            },
            toPinDevice() {
                this.$router.push({
                    name: 'PinDevice',
                });
            },
            // toPayCash() {
            //     this.$router.push({
            //         name: 'PayCash',
            //     });
            // },
            toPayLater() {
                this.$router.push({
                    name: 'PayLater',
                });
            },
            async setPaymentMethod(method: string) {
                await OrderService.sendPaymentMethod(method);
            },
        },
    });
