
    import { IonContent, IonPage } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { store } from '@/store';

    export default defineComponent({
        name: 'Auth',
        components: {
            IonContent,
            IonPage,
        },
        mounted() {
            // If we get a token in our query.
            if (this.$route.query.token) {
                // Set the azure access token.
                store.dispatch('auth/setAzureAccessToken', this.$route.query.token).then(() => {
                    // Redirect the user to the planning page.
                    this.$router.push({ name: 'Planning' });
                });
            }
        },

        created() {
            if (store.getters['auth/getAzureAccessToken']) {
                this.$router.push({ name: 'Planning' });
            }
        },
    });
